/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import {
  Col,
  css,
  Grid,
  Row,
  up,
  styled,
  th,
  withTheme,
} from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgDesktop1x from './images/login-bg-@1x.jpg';
import bgDesktop2x from './images/login-bg-@2x.jpg';
import bgMobile1x from './images/login-bg-mobile-@1x.jpg';
import bgMobile2x from './images/login-bg-mobile-@2x.jpg';
import bgTablet1x from './images/login-bg-tablet-@1x.jpg';
import bgTablet2x from './images/login-bg-tablet-@2x.jpg';
import logo from '../../theme/login-main_logo-@2x.png';

import RexelBanners from '../../components/CustomComponents/Banners/RexelBanners';

const LoginProvider = LoadAsync(() =>
  import(/* webpackChunkName: "loginProvider" */ '../RexelLogin/LoginProvider')
);
const LoginForm = LoadAsync(() =>
  import(/* webpackChunkName: "loginForm" */ '../RexelLogin/LoginForm')
);

const PageContainer = styled.div`
  align-items: center;
  background-color: ${th('primary')};
  background-image: url(${bgMobile1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgMobile2x});
  }

  ${up(
    'sm',
    css`
      background-image: url(${bgTablet1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bgTablet2x});
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url(${bgDesktop1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bgDesktop2x});
        background-color: ${th('primary')};
      }
    `
  )}
`;

const LoginLogo = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  font-weight: normal;
  img {
    max-height: 65px;
    width: auto;

    ${up(
      'md',
      css`
        height: 130px;
      `
    )};
  }
`;

const TagLine = styled.div`
  border-radius: 29.5px;
  opacity: 1;
  color: #000000;
  font-size: 31px;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;
`;

const IntroContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  ${up(
    'md',
    css`
      padding: 2rem;
    `
  )};
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
    &:visited {
      color: #fff;
    }
  }

  .warning {
    color: #dc3545;
    font-weight: bold;
    text-align: center;
  }

  .link-button-container {
    padding-top: 0;
    text-align: right;

    .forgot-username-button,
    .forgot-password-button,
    .registration-button {
      padding: 0 !important;
      color: #fff;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const LoginContainer = styled.div`
  max-width: 500px;

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }

  button {
    width: 170px;
    background-color: #000;
  }

  ${up(
    'lg',
    css`
      min-width: 300px;
    `
  )};
`;

const NewPageContainer = styled.div`
  font-size: 17px;
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <NewPageContainer>
      <RexelBanners login />
      <PageContainer>
        <Grid className="page-container">
          <IntroContainer>
            <Grid>
              <Row justifyContent="center">
                <Col xs={12}>
                  <LoginLogo className="text--center">
                    <img
                      src={logo}
                      alt={
                        settings.settingsApp && settings.settingsApp.siteName
                          ? settings.settingsApp.siteName
                          : 'ProgramName'
                      }
                    />
                    <br />
                    Welcome to <strong>Rexel Online Advantage</strong>
                  </LoginLogo>
                  <TagLine className="text--center">
                    Rewarding your{' '}
                    <span className="text--secondary">loyalty</span> in 2024
                    with <span className="text--secondary">MORE</span> suppliers
                    and <span className="text--secondary">MORE</span> gift
                    cards.<br />
                    Redeem your cards before the 31st December 2024
                  </TagLine>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                  <LoginContainer>
                    <h4>Login</h4>
                    <p>Please login below using your webstore details.</p>
                    <LoginProvider disableMeta>
                      <LoginForm formLabels={{ labelUserName: 'Username' }} />
                    </LoginProvider>
                  </LoginContainer>
                </Col>
                <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
                  <h4>The Program</h4>
                  <p>
                    <strong>Spend $500 + GST Online</strong>, on one order with
                    the participating vendor(s) and{' '}
                    <strong>earn a $50 e-gift card</strong>.
                  </p>
                  <p>
                    Redeem your gift cards on the Rexel Online Advantage
                    website. The more you spend, the more you earn.
                  </p>
                  <p>
                    <strong>
                      Promotion Period: 1 March – 30 November 2024
                    </strong>
                  </p>
                  <p>
                    You can earn gift cards on multiple purchases, as long as
                    each order achieves the spend requirement on nominated
                    products.{' '}
                    <strong>Limited to 6 e-gift cards per month</strong>.
                  </p>
                  <p>Terms and conditions apply, log in for more details.</p>
                  <p className="spacer--bottom--small">
                    For queries phone:{' '}
                    <a href="tel:1800845034" title="Call this phone number">
                      <strong>1800 845 034</strong>
                    </a>{' '}
                    or email{' '}
                    <a
                      href="mailto:info@rexelonlineadvantage.com.au"
                      title="Open an email in your default email app"
                    >
                      <strong>info@rexelonlineadvantage.com.au</strong>
                    </a>
                  </p>
                </Col>
              </Row>
            </Grid>
          </IntroContainer>
        </Grid>
      </PageContainer>
    </NewPageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
